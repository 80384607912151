import React from "react";
import Layout from "../component/Layout/Layout";
import {useStaticQuery, graphql} from "gatsby";
import Hero from "../component/Hero/Hero";
import {StaticImage} from "gatsby-plugin-image";
import Seo from "../component/Seo/Seo";
import "../assets/styles/default-page.sass";
export interface IPrivacyPolicyProps {}

const PrivacyPolicy: React.FC<IPrivacyPolicyProps> = () => {
  const {wpPage} = useStaticQuery(graphql`
    query PrivacyPolicy {
      wpPage(slug: {eq: "polityka-prywatnosci"}) {
        seo {
          metaDesc
          title
          focuskw
        }
        content
      }
    }
  `);
  return (
    <Layout>
      <Seo title={wpPage.seo.title} description={wpPage.seo.metaDesc} keywords={wpPage.seo.focuskw} />
      <Hero
        heading="Polityka prywatności"
        image={
          <StaticImage
            src="../assets/images/photo/hero-opinion.jpg"
            alt="Opinie o IKZE - czy warto?"
            title="Opinie o IKZE - czy warto?"
            height={600}
            width={828}
            quality={90}
          />
        }
        showCta={false}
        showParagraph={false}
      />
      <div className="container mw-1400">
        <div className="article-content">
          <div dangerouslySetInnerHTML={{__html: wpPage.content}} />
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
